import Axios from 'axios'

import generateCrudRoutes from 'services/generateCrudRoutes'
import searchURI from 'services/searchURI'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const buildFAQPayload = (faq) => {
  const faqAttributes = _.pick(faq, [
    'id',
    'question',
    'answer',
    'targetingRules',
  ])

  if (faq.targetingRules) {
    faqAttributes.targetingRules = normalizeTargetingRules(faq.targetingRules)
  }

  return faqAttributes
}

const adminFAQs = {
  ...generateCrudRoutes('/api/admin/faqs', 'faq', {}, buildFAQPayload),

  approve(faqId: string) {
    return Axios.patch(`/api/admin/faqs/${faqId}/approve`)
  },

  decline(faqId: string) {
    return Axios.patch(`/api/admin/faqs/${faqId}/decline`)
  },

  count(params = {}) {
    return Axios.get(searchURI('/api/admin/faqs/count', params))
  },
}

export default adminFAQs
