import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import { FAQType } from 'types/faq'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

export const buildFaqPayload = (faq: FAQType) => {
  const faqAttributes = _.pick(faq, [
    'id',
    'question',
    'answer',
    'targetingRules',
  ])

  if (faq.targetingRules) {
    faqAttributes.targetingRules = normalizeTargetingRules(faq.targetingRules)
  }

  return faqAttributes
}
const faqs = {
  ...generateCrudRoutes('/api/faqs', 'faq', {}, buildFaqPayload),

  activity(faqId: string) {
    return Axios.get(`/api/faqs/${faqId}/activity`)
  },
}

export default faqs
