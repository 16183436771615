import Axios from 'axios'
import qs from 'qs'
import snakeCaseKeys from 'utils/snakeCaseKeys'

export default {
  fetchOverview(params) {
    const queryString = qs.stringify(snakeCaseKeys(params))

    return Axios.get(`/api/admin/analytics/ai/assistant/answers_overview?${queryString}`)
  },

  fetchAnswers(params) {
    const queryString = qs.stringify(snakeCaseKeys(params))

    return Axios.get(`/api/admin/analytics/ai/assistant/answers?${queryString}`)
  },

  fetchAnswer(id, params) {
    const queryString = qs.stringify(snakeCaseKeys(params))

    return Axios.get(`/api/admin/analytics/ai/assistant/answers/${id}?${queryString}`)
  },

  exportXlsx(params) {
    return Axios.post('/api/admin/analytics/ai/assistant/answers/export_xlsx', params)
  },

  fetchInsights(params) {
    return Axios.post('/api/admin/analytics/ai/assistant/insight/theme_collections', params)
  },

  fetchInsightsIfJobFinished(params) {
    return Axios.post('/api/admin/analytics/ai/assistant/insight/theme_collections/load_if_job_finished', params)
  },

  evaluateAnswer(id) {
    return Axios.post(`/api/admin/analytics/ai/assistant/answers/${id}/evaluate`)
  },
}
