/* eslint camelcase: 0 */ // --> OFF
import Axios from 'axios'
import _ from 'lodash'
import { toast } from 'react-toastify'

import camelCaseKeys from 'utils/camelCaseKeys'
import snakeCaseKeysObjectsOnly from 'utils/snakeCaseKeysObjectsOnly'
import { invitablePath } from 'utils/routeHelpers'
import searchURI from 'services/searchURI'
import generateCrudRoutes from 'services/generateCrudRoutes'
import { extractToastMessage } from 'utils/errorHandling'
import React from 'react'
import NotLoggedIn from 'components/errors/notLoggedIn'
import { i18nPath } from 'utils/i18nHelpers'
import snakeCaseKeys from 'utils/snakeCaseKeys'
import { appendCroppedFormData } from 'utils/imageCrop'
import pageVariables from 'utils/pageVariables'
import { inMobileApp } from 'utils/denormalizedCurrentUser'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'
import messageMobileApp from 'utils/messageMobileApp'
import { notifySlackError } from 'utils/slack'

// API Services
import acknowledgements from 'services/api/acknowledgements'
import authTokens from 'services/api/authTokens'
import awardedBadges from 'services/api/badges/awardedBadges'
import badges from 'services/api/badges/badges'
import celebrations from 'services/api/celebrations'
import comments from 'services/api/comments'
import golinks from 'services/api/golinks'
import groups from 'services/api/groups'
import layouts from 'services/api/layouts'
import pagesNavigation from 'services/api/pagesNavigation'
import reactions from 'services/api/reactions'
import requestedBadges from 'services/api/badges/requestedBadges'
import shoutouts from 'services/api/shoutouts'
import users from 'services/api/users'
import videos from 'services/api/videos'
import activities from 'services/api/activities'

// Admin API Services
import adminAcknowledgements from 'services/api/admin/acknowledgements'
import adminArticles from 'services/api/admin/articles'
import adminAnalytics from 'services/api/admin/analytics'
import adminCorrespondents from 'services/api/admin/journey/correspondents'
import adminLayouts from 'services/api/admin/layouts'
import adminVideos from 'services/api/admin/videos'
import assistantApi from 'services/api/ai/assistant'
import banners from 'services/api/admin/banners'
import promptCollections from 'services/api/admin/ai/chat/promptCollections'
import chat from 'services/api/chat'
import communicationTemplates from 'services/api/admin/journey/communicationTemplates'
import companyConfigurations from 'services/api/companyConfiguration'
import companyValues from 'services/api/admin/companyValues'
import defaultUserSettings from 'services/api/defaultUserSettings'
import externalSync from 'services/api/admin/externalSync'
import journeyBlueprints from 'services/api/admin/journey/journeyBlueprints'
import journeys from 'services/api/admin/journey/journeys'
import logos from 'services/api/admin/logos'
import meetingTemplates from 'services/api/admin/journey/meetingTemplates'
import userBillingAccessLevelSnapshots from 'services/api/admin/userBillingAccessLevelSnapshots'
import userSkills from 'services/api/admin/userSkills'
import userSkillTypes from 'services/api/admin/userSkillTypes'
import faqs from 'services/api/faqs'
import conversationsApi from 'services/api/ai/conversations'
import messagesApi from 'services/api/ai/messages'
import promptCollectionsApi from 'services/api/ai/promptCollections'
import adminConversationsApi from 'services/api/admin/ai/chat/conversations'
import adminMessagesApi from 'services/api/admin/ai/chat/messages'
import adminTicketing from 'services/api/admin/ticketing'
import ticketing from 'services/api/ticketing'
import workflows from 'services/api/admin/workflows'
import aiEvaluationRuns from 'services/api/admin/ai/evaluation/runs'
import aiEvaluationAnswers from 'services/api/admin/ai/evaluation/answers'
import aiEvaluationQuestions from 'services/api/admin/ai/evaluation/questions'
import aiEvaluationDatasets from 'services/api/admin/ai/evaluation/datasets'
import adminFAQs from 'services/api/admin/faqs'
import ipAccessRules from 'services/api/admin/ipAccessRules'

const I18N = i18nPath('views.common')

export const buildExternalCalendarPayload = (externalCalendar) => {
  const payload = _.pick(externalCalendar, [
    'id',
    'calendarUrl',
    'internalName',
    'displayName',
    'color',
    'calendarId',
    'calendarOwnerEmail',
    'calendarType',
    'visibleOnHomepage',
  ])

  if (externalCalendar.owner) {
    payload.ownerId = externalCalendar.owner.id
  }

  if (externalCalendar.targetingRules) {
    payload.targetingRules = normalizeTargetingRules(externalCalendar.targetingRules)
  }

  return payload
}

let initCsrfToken

const API = {
  init(csrfToken) {
    initCsrfToken = csrfToken
    Axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

    // This line does not do anything helpful, as far as i can see.
    // Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

    // snakeCaseKeys only Objects, and not things like FormData
    Axios.defaults.transformRequest = [snakeCaseKeysObjectsOnly, ...Axios.defaults.transformRequest]

    // Transform json response keys from snake_case to camelCase
    Axios.defaults.transformResponse = [...Axios.defaults.transformResponse, camelCaseKeys]

    // Creating a standard unwrapping / redux handler for Errors to match the errors.rb api design.
    const errorHandler = (error) => {
      const toastMessage = extractToastMessage(error.response)

      // Currently making this behavior opt-in so we can migrate to it in a separate PR
      const shouldHandleErrors = (config = {}) => (!!(Object.prototype.hasOwnProperty.call(config, 'handleErrors') && config.handleErrors))

      // In case of CI environment we want to always show the error so we can assert on Cypress that no error was shown
      if ((shouldHandleErrors(error.config) && toastMessage) || process.env.CI) {
        toast.error(toastMessage, { autoClose: process.env.CI ? false : 5000 })
      }

      const { status, data } = error.response || {}

      // errors.rb -> CLEARY_NOT_AUTHENTICATED_HTTP_STATUS = 490
      if (status === 490) {
        const handleNotLoggedInOnClick = () => {
          API.auth
            .logout()
            .then((response) => {
              // directs you to the logout form, which will log you out of google (if logged in)
              // this is necessary because the google JS can only be loaded from
              // the login subdomain (login.gocleary.com, login.staging1.clearytesting.com, login.localhost:3000, etc)
              window.location.assign(response.data.redirectUrl)
            })
            .catch(() => {
              // handle logout error
            })
        }

        if (inMobileApp) {
          messageMobileApp({
            action: 'webviewLoggedOut',
            pathname: window.location.pathname,
            search: window.location.search,
          })
        } else {
          toast.error(() => <NotLoggedIn onClick={handleNotLoggedInOnClick} />, { autoClose: false, toastId: 'notLoggedIn' })
        }
      }

      if (status === 413) {
        toast.error(I18n.t('views.errors.payload_too_large'))
      }

      // Complete the Promise chain
      return Promise.reject(error)
    }

    // We decided to not have this in every deployment as it was becoming too noisy and most of the time it's fine to use the old app version
    // We'll eventually add this back for specific cases though, hence leaving it here for quick reference
    const validateAppRevision = (response) => {
      // should not be run during CI
      if (process.env.CI) return response

      const { revision: loadedAppRevision } = pageVariables

      // ApplicationController sets the app-revision header
      const updatedAppRevision = response.headers['app-revision']

      // if loadedAppRevision isn't set is because the app is not loaded yet
      // updatedAppRevision not set means it's a mocked api response, this check is useful for cypress tests
      if (loadedAppRevision && updatedAppRevision && (loadedAppRevision !== updatedAppRevision) && !inMobileApp) {
        toast.warn(I18N('outdated_app'), { autoClose: false, toastId: 'outdatedApp' })
      }

      return response
    }

    Axios.interceptors.response.use(
      response => response, // response => validateAppRevision(response),
      error => errorHandler(error)
    )

    const verifyRequest = (config) => {
      const message = 'search.json with type=user&per_page=5&page=1 identified'

      if (typeof config.url === 'string' || config.url instanceof String) {
        const { url: path, params } = config

        if (path === '/api/search.json' && params?.page === 1 && params?.per_page === 5 && params?.type === 'user') {
          throw new Error(message)
        }
      } else {
        const { url: { _parts: { path, query } } } = config

        if (path === '/api/search.json' && query.includes('type=user&per_page=5&page=1')) {
          throw new Error(message)
        }
      }
    }

    const identifySearchJsonUserType = (config) => {
      try {
        verifyRequest(config)
      } catch (e) {
        e.message = e.stack

        notifySlackError({
          action: 'create',
          exception: e,
          modelType: 'Article',
        })
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return config
      }
    }

    Axios.interceptors.request.use(
      config => identifySearchJsonUserType(config),
      error => Promise.reject(error)
    )
  },

  csrfToken() {
    return initCsrfToken
  },

  auth: {
    loginWithPassword(email, password, redirectUrl) {
      return Axios.post('/api/login_with_password', { email, password, redirectUrl })
    },

    logout() {
      return Axios.delete('/api/logout')
    },

    getAuthType(companyName, params = {}) {
      return Axios.get(searchURI('/api/auth_type', { company: companyName, ...params }))
    },

    getAuthTypeByEmail(email) {
      return Axios.get(searchURI('/api/auth_type_by_email', { email }))
    },

    generateMagicLink(data) {
      return Axios.post('/api/generate_magic_link', data)
    },

    admin: {
      generateMagicLink(data) {
        return Axios.post('/api/admin/generate_magic_link', data)
      },
    },
  },

  acknowledgements,
  activities,
  authTokens,
  awardedBadges,
  badges,
  celebrations,
  comments,
  golinks,
  groups,
  layout: layouts,
  pagesNavigation,
  faqs,
  reactions,
  requestedBadges,
  shoutouts,
  users,
  videos,

  globalSearch(query, params = {}) {
    return Axios.get('/api/search', {
      params: {
        query,
        ...snakeCaseKeys(params),
      },
    })
  },

  googleDriveSearch(query, params = {}) {
    return Axios.get('/api/search/google_drive_search', {
      params: {
        query,
        ...snakeCaseKeys(params),
      },
    })
  },

  confluenceSearch(query, params = {}) {
    return Axios.get('/api/search/confluence_search', {
      params: {
        query,
        ...snakeCaseKeys(params),
      },
    })
  },

  bloomfireSearch(query, params = {}) {
    return Axios.get('/api/search/bloomfire_search', {
      params: {
        query,
        ...snakeCaseKeys(params),
      },
    })
  },

  oneDriveSearch(query, params = {}) {
    return Axios.get('/api/search/one_drive_search', {
      params: {
        query,
        ...snakeCaseKeys(params),
      },
    })
  },

  notionSearch(query) {
    return Axios.get('/api/search/notion_search', {
      params: {
        query,
      },
    })
  },

  // Feature APIs
  getUserProfile(id) {
    // TODO: call a profile-specific endpoint that returns a user and all relationships needed to render a Profile
    return Axios.get(`/api/users/${id}`) // Note that we dont specify  to allow usernames with periods in them
  },

  sendFeedback(type, message, url) {
    return Axios.post('/api/feedback', { payload: { type, message, url } })
  },

  home: {
    fetchFeed(opts = { page: 1 }) {
      return Axios.get(searchURI('/api/home', opts))
    },

    excludeFromFeed(feedItem) {
      return Axios.post('/api/home/exclude_from_feed', { feedItemId: feedItem.id })
    },

    subscribe(feedItem) {
      return Axios.post('/api/home/subscribe', { feedItemId: feedItem.id })
    },

    unsubscribe(feedItem) {
      return Axios.post('/api/home/unsubscribe', { feedItemId: feedItem.id })
    },

    acceptLegalNotices() {
      return Axios.post('/api/home/accept_legal_notices')
    },
  },

  audiences: {
    fetchEstimatedAudience(params) {
      return Axios.post('/api/target_audiences/estimate', params)
    },

    fetchEstimatedPreboardingAudience(params) {
      return Axios.post('/api/target_audiences/estimate_preboarding', params)
    },

    estimateWithNewsSubscriptions(params) {
      return Axios.post('/api/target_audiences/estimate_with_news_subscriptions', params)
    },

    fetchJourneysEstimatedAudience(params) {
      return Axios.post('/api/target_audiences/journeys_estimate', params)
    },

    fetchGroupPostEstimatedAudience(params) {
      return Axios.post('/api/target_audiences/group_post_estimate', params)
    },

    fetchTargetingOptions() {
      return Axios.get('/api/target_audiences/targeting_options')
    },
  },

  calendars: {
    fetchUpcomingEvents(numEvents = 5) {
      return Axios.get(searchURI('/api/calendars/upcoming_events', { numEvents }))
    },

    fetchEvents(username) {
      return Axios.get(`/api/calendars/${username}/user_events`)
    },
  },

  orgChart: {
    getOrgChart() {
      return Axios.get('/api/org_chart')
    },

    getOrgChartUsers(username) {
      return Axios.get(searchURI('/api/org_chart/users_for_org_chart', { username }))
    },

    getProfilePreviewForUser(username) {
      return Axios.get(searchURI('/api/org_chart/profile_preview_user', { username }))
    },
  },

  richTextInlineComments: {
    fetchAll(clearyRichTextId) {
      return Axios.get(`/api/cleary_rich_texts/${clearyRichTextId}/rich_text_inline_comments`)
    },

    create(clearyRichTextId, body = {}) {
      return Axios.post(`/api/cleary_rich_texts/${clearyRichTextId}/rich_text_inline_comments`, { richTextInlineComment: body })
    },
  },

  articles: {
    fetchAll(opts = { page: 1 }) {
      return Axios.get(searchURI('/api/articles', opts))
    },

    fetchByMentionedOrAuthoredUser(opts = { page: 1 }) {
      return Axios.get(searchURI('/api/articles/by_mentioned_or_authored_user', opts))
    },

    fetch(id) {
      return Axios.get(`/api/articles/${id}`)
    },

    subscribe(article) {
      return Axios.post(`/api/articles/${article.id}/subscribe`)
    },

    unsubscribe(article) {
      return Axios.post(`/api/articles/${article.id}/unsubscribe`)
    },

    includeInCommentNotifications(article) {
      return Axios.post(`/api/articles/${article.id}/include_in_comment_notifications`)
    },

    excludeFromCommentNotifications(article) {
      return Axios.post(`/api/articles/${article.id}/exclude_from_comment_notifications`)
    },

    trackArticleViewStart(article, route) {
      return Axios.post(`/api/articles/${article.id}/user_actions/view_start`, { route })
    },

    trackArticleViewEnd(article, userActionID) {
      return Axios.patch(`/api/articles/${article.id}/user_actions/${userActionID}/view_end`)
    },

    trackLinkClick(articleID, route, link) {
      return Axios.post(`/api/articles/${articleID}/user_actions/link_click`, { route, link })
    },

    pendingAcknowledgement() {
      return Axios.get('/api/articles/pending_acknowledgement')
    },

    socialShare() {
      return Axios.get('/api/articles/social_share')
    },

    targeted(opts = { page: 1 }) {
      return Axios.get(searchURI('/api/articles/targeted', opts))
    },
  },

  articleTypes: {
    fetchAll() {
      return Axios.get('/api/article_types')
    },

    fetchGroupArticleType() {
      return Axios.get('/api/article_types/group_article_type')
    },
  },

  banners: {
    fetchAll() {
      return Axios.get('/api/banners')
    },
  },

  carouselCards: {
    fetchAll() {
      return Axios.get('/api/carousel_cards')
    },
  },

  employees: {
    fetchNewEmployees(opts = { week: 'this_week', page: 1 }) {
      return Axios.get(searchURI('/api/users/new_employees', opts))
    },
    fetchWorkAnniversaries(opts = { week: 'this_week', page: 1 }) {
      return Axios.get(searchURI('/api/users/work_anniversaries', opts))
    },
    fetchBirthdays(opts = { week: 'this_week', page: 1 }) {
      return Axios.get(searchURI('/api/users/birthdays', opts))
    },
  },

  groupTypes: {
    fetchAll() {
      return Axios.get('/api/group_types')
    },
  },

  temporaryImageUpload: {
    create(imageData, clearyRichTextId) {
      const body = new FormData()
      body.append('image_data', imageData)
      if (clearyRichTextId) {
        body.append('cleary_rich_text_id', clearyRichTextId)
      }

      return Axios.post('/api/temporary_image_upload', body)
    },
  },

  attachments: {
    create(file, clearyRichTextId) {
      const formData = new FormData()
      formData.append('file', file)
      if (clearyRichTextId) {
        formData.append('cleary_rich_text_id', clearyRichTextId)
      }

      return Axios.post('/api/attachments', formData)
    },

    duplicate(fileId, clearyRichTextId) {
      const body = { clearyRichTextId }

      return Axios.post(`/api/attachments/${fileId}/duplicate`, body)
    },

    show(id, opts = {}) {
      return Axios.get(searchURI(`/api/attachments/${id}`, opts))
    },
  },

  companyValues: {
    fetchAll() {
      return Axios.get('/api/company_values')
    },
  },

  tiptapEditor: {
    fetchCollaborationToken(options) {
      return Axios.get('/api/tiptap_editor/collaboration_token', snakeCaseKeysObjectsOnly({ params: options }))
    },

    trackLatency(latency) {
      return Axios.post('/api/tiptap_editor/track_latency', { latency })
    },
  },

  pages: _.merge(generateCrudRoutes('/api/pages', 'page'), {
    fetch(id, queryParams = {}) {
      return Axios.get(searchURI(`/api/pages/${id}`, queryParams))
    },

    copy(page) {
      return Axios.post(`/api/pages/${page.id}/copy`, { page })
    },

    uploadCoverImage(id, coverImage, croppedAreaPixels) {
      const pageFormData = new FormData()
      pageFormData.append('page[draft_cover_image]', coverImage, coverImage.fileName)
      appendCroppedFormData(pageFormData, 'page', 'draft_cover_image_cropped_area', croppedAreaPixels)

      return Axios.patch(`/api/pages/${id}`, pageFormData)
    },

    removeCoverImage(id) {
      return Axios.delete(`/api/pages/${id}/cover_image`)
    },

    repositionCoverImage(id, croppedAreaPixels) {
      return API.pages.update({ id, draftCoverImageCroppedArea: croppedAreaPixels })
    },

    revertToDraft(id) {
      return Axios.patch(`/api/pages/${id}/revert_to_draft`)
    },

    applyTemplate(params) {
      return Axios.patch(`/api/pages/${params.pageId}/apply_template`, params)
    },

    autosave(page) {
      return Axios.patch(`/api/pages/${page.id}/autosave`, { page })
    },

    publish(page) {
      return Axios.patch(`/api/pages/${page.id}/publish`, { page })
    },

    setExpiration(page) {
      return Axios.patch(`/api/pages/${page.id}/set_expiration`, { page })
    },

    unarchive(page) {
      return Axios.patch(`/api/pages/${page.id}/unarchive`, { page })
    },

    markAsUpToDate(params) {
      return Axios.patch('/api/pages/mark_as_up_to_date', params)
    },

    fetchArchivedPages(params) {
      return Axios.get(searchURI('/api/pages/archived_pages', params))
    },

    restoreFromSnapshot(id, snapshotId) {
      return Axios.patch(`/api/pages/${id}/restore_from_snapshot`, { snapshotId })
    },

    snapshots: {
      fetchAll(pageId, params = {}) {
        return Axios.get(searchURI(`/api/pages/${pageId}/snapshots`, params))
      },
    },
  }),

  profile: {
    fetch(id, queryParams = {}) {
      return Axios.get(searchURI(`/api/users/${id}`, queryParams)) // Note that we dont specify  to allow usernames with periods in them
    },

    update(user, change) {
      // namePronunciationRecording and videoIntroductionRecording are files so it must be sent as FormData
      // FormData cant send null (it sends a string) so when no file is sent it cant use FormData
      let body = { user: change }
      if (change?.namePronunciationRecording || change?.videoIntroductionRecording) {
        body = new FormData()
        Object.entries(change).forEach(([key, value]) => body.append(`user[${_.snakeCase(key)}]`, value))
      }

      return Axios.patch(`/api/users/${user.id}`, body)
    },

    photos: {
      create(image, user, croppedAreaPixels) {
        const formData = new FormData()

        formData.append('photo[image]', image, image.fileName)
        formData.append('photo[owner_id]', user.id)
        appendCroppedFormData(formData, 'photo', 'image_cropped_area', croppedAreaPixels)

        return Axios.post(`/api/users/${user.id}/profile_photos`, formData)
      },

      destroy(photo) {
        return Axios.delete(`/api/users/${photo.ownerId}/profile_photos/${photo.id}`)
      },

      promote(photo) {
        return Axios.patch(`/api/users/${photo.ownerId}/profile_photos/${photo.id}/promote`)
      },

      update(photo) {
        const formData = new FormData()

        appendCroppedFormData(formData, 'photo', 'image_cropped_area', photo.imageCroppedArea)

        return Axios.patch(`/api/users/${photo.ownerId}/profile_photos/${photo.id}`, formData)
      },

      admin: {
        importFromSlack() {
          return Axios.post('/admin/profile_photos/import_from_slack')
        },
      },
    },
  },

  notifications: {
    fetchAll() {
      return Axios.get('/api/notifications')
    },

    markAsViewed(notificationIds) {
      return Axios.post('/api/notifications/mark_as_viewed', { notificationIds })
    },
  },

  settings: {
    fetchAll() {
      return Axios.get('/api/settings')
    },

    fetch(name) {
      return Axios.get(`/api/settings/${name}`)
    },

    update(setting) {
      return Axios.patch('/api/settings', { setting })
    },
  },

  tags: {
    fetchAll() {
      return Axios.get('/api/tags')
    },
  },

  invitations: {
    batchCreate(invitable, type, params) {
      return Axios.post(`${invitablePath(invitable, type)}/batch_create`, params)
    },
  },

  qna: {
    events: {
      fetchAll(queryParams = {}) {
        return Axios.get(searchURI('/api/qna/events', queryParams))
      },
      fetch(id) {
        return Axios.get(`/api/qna/events/${id}`)
      },
      fetchHighlightedEvent() {
        return Axios.get('/api/qna/events/highlighted_event')
      },
      requestInvite(id) {
        return Axios.post(`/api/qna/events/${id}/request_invite`)
      },
    },
    questions: _.merge(generateCrudRoutes('/api/qna/questions', 'question'), {
      create(questionParams) {
        return Axios.post('/api/qna/questions', questionParams)
      },
      upvote(question) {
        return Axios.post(`/api/qna/questions/${question.id}/upvote`)
      },
      downvote(question) {
        return Axios.post(`/api/qna/questions/${question.id}/downvote`)
      },
      unvote(question) {
        return Axios.post(`/api/qna/questions/${question.id}/unvote`)
      },
      fetchRecentQuestions() {
        return Axios.get('api/qna/questions/recent')
      },
    }),
    answers: {
      fetchAll(questionId) {
        return Axios.get(`/api/qna/questions/${questionId}/answers`)
      },

      create(answerParams) {
        return Axios.post(`/api/qna/questions/${answerParams.questionId}/answers`, answerParams)
      },

      destroy(answer) {
        return Axios.delete(`/api/qna/questions/${answer.questionId}/answers/${answer.id}`)
      },

      accept(answer) {
        return Axios.patch(`/api/qna/questions/${answer.questionId}/answers/${answer.id}/accept`)
      },

      verify(answer) {
        return Axios.patch(`/api/qna/questions/${answer.questionId}/answers/${answer.id}/verify`)
      },
    },
    icebreakerQuestions: {
      fetchAll() {
        return Axios.get('/api/qna/template_questions')
      },
    },
  },

  teamMembers: {
    create(teamMember) {
      return Axios.post(`/api/teams/${teamMember.teamId}/team_members`, { teamMember })
    },

    destroy(teamMember) {
      return Axios.delete(`/api/teams/${teamMember.team.id}/team_members/${teamMember.id}`)
    },
  },

  userIntegrations: {
    fetchIntegrations() {
      return Axios.get('/api/user_integrations')
    },
    uninstallZoomIntegration() {
      return Axios.delete('/api/user_integrations/uninstall_zoom')
    },
    uninstallSlackIntegration() {
      return Axios.delete('/api/user_integrations/uninstall_slack')
    },
    uninstallOneDriveIntegration() {
      return Axios.delete('/api/user_integrations/uninstall_one_drive')
    },
  },

  timezones: {
    fetchAll() {
      return Axios.get('/api/timezones')
    },
  },

  userSkills: {
    createTagging(params) {
      return Axios.post('/api/user_skill_taggings', params)
    },

    destroyTagging(params) {
      return Axios.delete('/api/user_skill_taggings', { data: params })
    },

    fetchAll() {
      return Axios.get('/api/user_skills')
    },

    fetch(id) {
      return Axios.get(`/api/user_skills/${id}`)
    },

    fetchUsers(id, params) {
      return Axios.get(`/api/user_skills/${id}/users`, { params })
    },
  },

  userSkillTypes: {
    fetchAll() {
      return Axios.get('/api/user_skill_types')
    },
  },

  chat,

  slackErrors: {
    notifyError(params) {
      return Axios.post('/api/slack/error_notifications/notify_error', params)
    },
  },

  userFollows: {
    ...generateCrudRoutes('/api/user_follows', 'user_follow'),

    fetchOrgSectionFollowedUserIds() {
      return Axios.get('/api/user_follows/org_section_followed_user_ids')
    },

    fetchFollowingStatus(userId) {
      return Axios.get(`/api/user_follows/following_status?user_id=${userId}`)
    },
  },

  journey: {
    onboardingBuddies: {
      assignedBuddy() {
        return Axios.get('/api/journey/onboarding_buddies/assigned_buddy')
      },

      fetchAll(params) {
        return Axios.get('/api/journey/onboarding_buddies', snakeCaseKeysObjectsOnly({ params }))
      },

      optIn() {
        return Axios.post('/api/journey/onboarding_buddies/opt_in')
      },

      optOut() {
        return Axios.post('/api/journey/onboarding_buddies/opt_out')
      },

      doNotWantOnboardingBuddy() {
        return Axios.post('/api/journey/onboarding_buddies/do_not_want_onboarding_buddy')
      },

      create(onboardingBuddyId) {
        return Axios.post('/api/journey/onboarding_buddies', { onboardingBuddyId })
      },
    },

    communications: {
      fetch(id) {
        return Axios.get(`/api/journey/communications/${id}`)
      },
    },

    tasks: {
      fetch(id) {
        return Axios.get(`/api/journey/tasks/${id}`)
      },

      complete(id, params) {
        return Axios.patch(`/api/journey/tasks/${id}/complete`, params)
      },

      reopen(id) {
        return Axios.patch(`/api/journey/tasks/${id}/reopen`)
      },

      toggleNotifications(id, params) {
        return Axios.patch(`/api/journey/tasks/${id}/toggle_notifications`, params)
      },
    },

    taskableSteps: {
      fetchAll(queryParams) {
        return Axios.get(searchURI('/api/journey/taskable_steps', queryParams))
      },

      getDirectReportsCountByTaskStatus() {
        return Axios.get('/api/journey/taskable_steps/direct_reports_task_count_by_status')
      },
    },

    introductions: {
      fetch(id) {
        return Axios.get(`/api/journey/introductions/${id}`)
      },

      complete(id, introductionInvitationsAttributes) {
        return Axios.patch(`/api/journey/introductions/${id}/complete`, { introductionInvitationsAttributes })
      },

      reopen(id) {
        return Axios.patch(`/api/journey/introductions/${id}/reopen`)
      },

      toggleNotifications(id, params) {
        return Axios.patch(`/api/journey/introductions/${id}/toggle_notifications`, params)
      },
    },

    preboarding: {
      articles: {
        fetch(slug) {
          return Axios.get(`/api/journey/preboarding/articles/${slug}`)
        },
      },
      carouselCards: {
        fetchAll(widgetId) {
          return Axios.get('/api/journey/preboarding/carousel_cards', snakeCaseKeysObjectsOnly({ params: { widgetId } }))
        },
      },

      home: {
        fetchFeed(params = { page: 1 }) {
          return Axios.get(searchURI('/api/journey/preboarding/home', params))
        },
      },

      pages: {
        fetchAll() {
          return Axios.get('/api/journey/preboarding/pages')
        },

        fetch(slug) {
          return Axios.get(`/api/journey/preboarding/pages/${slug}`)
        },
      },

      users: {
        fetch(username) {
          return Axios.get(`/api/journey/preboarding/users/${username}`)
        },
      },
    },
  },

  ai: {
    textGenerations: generateCrudRoutes('/api/ai/text_generations', 'text_generation'),

    chats: {
      create(messages, searchSessionId, model) {
        return Axios.post('/api/ai/chats', { chat: { messages, searchSessionId, model } })
      },

      conversations: conversationsApi,
      messages: messagesApi,
      promptCollections: promptCollectionsApi,
    },

    assistant: assistantApi,
  },

  appLauncher: {
    apps: {
      fetchAll(params) {
        return Axios.get(searchURI('/api/app_launcher/apps', params))
      },
    },

    appPreferences: {
      create(appPreference) {
        return Axios.post('/api/app_launcher/app_preferences', appPreference)
      },

      update(appPreference) {
        return Axios.patch(`/api/app_launcher/app_preferences/${appPreference.id}`, appPreference)
      },

      destroy(id) {
        return Axios.delete(`/api/app_launcher/app_preferences/${id}`)
      },
    },
  },

  pageTemplateCategories: {
    fetchAll() {
      return Axios.get('/api/page/template_categories')
    },
  },

  pageTemplates: {
    fetchAll(templateCategoryId, queryParams) {
      return Axios.get(searchURI(`/api/page/template_categories/${templateCategoryId}/templates`, queryParams))
    },

    fetch(params) {
      const { id, templateCategoryId } = params
      return Axios.get(`/api/page/template_categories/${templateCategoryId}/templates/${id}`)
    },
  },

  pageWorkspaces: generateCrudRoutes('/api/page/workspaces', 'workspace'),

  surveyResponses: {
    create(formId, params = {}) {
      return Axios.post(`/api/survey/form/${formId}/responses`, params)
    },

    submit(formId, responseId) {
      return Axios.patch(`/api/survey/form/${formId}/responses/${responseId}/submit`)
    },
  },

  surveyAnswers: {
    create(formId, responseId, surveyAnswer) {
      return Axios.post(`/api/survey/form/${formId}/responses/${responseId}/answers`, { surveyAnswer })
    },
  },

  surveyForms: {
    ...generateCrudRoutes('/api/survey/forms', 'form'),

    fetchActiveSurvey(params) {
      return Axios.get('/api/survey/forms/active_form', { params })
    },
  },

  feedback: {
    fetchAll(feedbackableId, feedbackableType, queryParams) {
      return Axios.get(searchURI(`/api/${feedbackableType}/${feedbackableId}/feedbacks`, queryParams))
    },

    create(feedbackableId, feedbackableType, feedback) {
      return Axios.post(`/api/${feedbackableType}/${feedbackableId}/feedbacks`, { feedback })
    },

    update(feedbackableId, feedbackableType, feedback) {
      return Axios.patch(`/api/${feedbackableType}/${feedbackableId}/feedbacks/${feedback.id}`, { feedback })
    },

    destroy(feedbackableId, feedbackableType, feedbackId) {
      return Axios.delete(`/api/${feedbackableType}/${feedbackableId}/feedbacks/${feedbackId}`)
    },
  },

  customReactions: generateCrudRoutes('/api/custom_reactions', 'custom_reaction'),

  ticketing,

  // ADMIN
  admin: {
    ipAccessRules,
    analytics: adminAnalytics,
    acknowledgements: adminAcknowledgements,
    articles: adminArticles,
    banners,
    companyValues,
    faqs: adminFAQs,
    layout: adminLayouts,
    logos,
    userSkills,
    userSkillTypes,
    videos: adminVideos,
    externalSync,
    ticketing: adminTicketing,

    userSnapshots: generateCrudRoutes('/api/admin/user_snapshots', 'user_snapshot'),
    userBillingAccessLevelSnapshots,
    versions: { fetchAll(params = {}) { return Axios.get('/api/admin/versions', { params }) } },
    msTeamWorkspaces: {
      listTeams(workspaceId, params) {
        return Axios.get(`/api/admin/ms_team_workspaces/${workspaceId}/list_teams`, { params })
      },
    },

    tagSubscriptions: {
      fetchAll() {
        return Axios.get('/api/admin/tag_subscriptions')
      },

      updateCompanyAndUsersTagsSubscriptions(params) {
        return Axios.post('/api/admin/tag_subscriptions/update_company_and_users_tags_subscriptions', params)
      },
    },

    sftpSshPublicKeys: {
      fetch() {
        return Axios.get('/api/admin/sftp_ssh_public_keys')
      },

      installKey(sshPublicKeyBody) {
        return Axios.post('/api/admin/sftp_ssh_public_keys', { clearySftp: { sshPublicKeyBody } })
      },

      deleteKey(sshPublicKeyId) {
        return Axios.delete(`/api/admin/sftp_ssh_public_keys/${sshPublicKeyId}`)
      },
    },

    authTokens: {
      fetchUserAuthTokens(userId) {
        return Axios.get(`/api/admin/users/${userId}/auth_tokens`)
      },

      createAuthToken(userId) {
        return Axios.post(`/api/admin/users/${userId}/auth_tokens`)
      },

      destroyAuthToken(userId, authTokenId) {
        return Axios.delete(`/api/admin/users/${userId}/auth_tokens/${authTokenId}`)
      },
    },
    workflows,
    ai: {
      generatePromptContext(params) {
        return Axios.post('/api/admin/ai/generate_prompt_context', params)
      },

      generateText(params) {
        return Axios.post('/api/admin/ai/generate_text', params)
      },

      callLLM(params) {
        return Axios.post('/api/admin/ai/assistant/llm/call_llm', params)
      },

      chat: {
        promptCollections,
        conversations: adminConversationsApi,
        messages: adminMessagesApi,
      },

      evaluation: {
        runs: aiEvaluationRuns,
        answers: aiEvaluationAnswers,
        questions: aiEvaluationQuestions,
        datasets: aiEvaluationDatasets,
      },
    },
    pageTemplates: {
      create(pageTemplate) {
        const { templateCategoryId } = pageTemplate
        return Axios.post(`/api/admin/page/template_categories/${templateCategoryId}/templates`, { pageTemplate })
      },

      createBaseTemplate(pageTemplate) {
        const { templateCategoryId } = pageTemplate
        return Axios.post(`/api/admin/page/template_categories/${templateCategoryId}/templates/create_base_template`, { pageTemplate })
      },

      update(pageTemplate) {
        const { id, templateCategoryId } = pageTemplate
        return Axios.patch(`/api/admin/page/template_categories/${templateCategoryId}/templates/${id}`, { pageTemplate })
      },

      destroy(pageTemplate) {
        const { id, templateCategoryId } = pageTemplate
        return Axios.delete(`/api/admin/page/template_categories/${templateCategoryId}/templates/${id}`)
      },

      uploadCoverImage(id, coverImage, croppedAreaPixels, options) {
        const pageTemplateFormData = new FormData()
        pageTemplateFormData.append('page_template[cover_image]', coverImage, coverImage.fileName)
        appendCroppedFormData(pageTemplateFormData, 'page_template', 'cover_image_cropped_area', croppedAreaPixels)

        return Axios.patch(`${options.customBaseUrl}`, pageTemplateFormData)
      },

      removeCoverImage(id, options) {
        return Axios.delete(`${options.customBaseUrl}/cover_image`)
      },

      repositionCoverImage(id, croppedAreaPixels, options) {
        return Axios.patch(`${options.customBaseUrl}`, { pageTemplate: { id, coverImageCroppedArea: croppedAreaPixels } })
      },
    },
    invitations: {
      resendInvitation(invitationId) {
        return Axios.post(`/api/admin/invitations/${invitationId}/resend`)
      },
    },

    displayAuthors: {
      fetchAll() {
        return Axios.get('/api/admin/display_authors')
      },
    },

    qna: {
      events: _.merge(generateCrudRoutes('/api/admin/qna/events', 'event', { handleErrors: true }), {
        publish(eventId) {
          return Axios.patch(`/api/admin/qna/events/${eventId}/publish`)
        },
        fetchInvitations(eventId) {
          return Axios.get(`/api/admin/qna/events/${eventId}/invitations`)
        },
        eventHasZoomRecording(eventId) {
          return Axios.get(`/api/admin/qna/events/${eventId}/event_has_zoom_recording`)
        },
        uploadCoverImage(id, coverImage) {
          const eventFormData = new FormData()
          eventFormData.append('event[cover_image]', coverImage, coverImage.fileName)
          eventFormData.append('event[template_name]', '')

          return Axios.patch(`/api/admin/qna/events/${id}`, eventFormData)
        },
        removeCoverImage(id) {
          return Axios.delete(`/api/admin/qna/events/${id}/cover_image`)
        },
        generateRecapArticle(eventId) {
          return Axios.post(`/api/admin/qna/events/${eventId}/generate_recap_article`)
        },
      }),
      questions: {
        fetchAll(eventId, queryParams) {
          return Axios.get(searchURI(`/api/admin/qna/events/${eventId}/questions`, queryParams))
        },
        approve(eventId, questionId) {
          return Axios.patch(`/api/admin/qna/events/${eventId}/questions/${questionId}/approve`)
        },
        reject(eventId, questionId, rejectionReason) {
          return Axios.patch(`/api/admin/qna/events/${eventId}/questions/${questionId}/reject`, { rejectionReason })
        },
      },
      speakers: {
        create(eventId, userId) {
          return Axios.post(`/api/admin/qna/events/${eventId}/event_speakers/${userId}`)
        },
        destroy(eventId, userId) {
          return Axios.delete(`/api/admin/qna/events/${eventId}/event_speakers/${userId}`)
        },
      },
    },

    journey: {
      communicationTemplates,
      correspondents: adminCorrespondents,
      journeyBlueprints,
      journeys,
      meetingTemplates,
    },

    article: {
      templates: _.merge(generateCrudRoutes('/api/admin/article/templates', 'article_template'), {
        copy(id, title) {
          return Axios.post(`/api/admin/article/templates/${id}/copy`, { articleTemplate: { title } })
        },
      }),

      templateCategories: generateCrudRoutes('/api/admin/article/template_categories', 'article_template_category'),
    },

    defaultPermissions: _.merge(generateCrudRoutes('/api/admin/default_permissions', 'default_permission'), {

    }),

    tags: generateCrudRoutes('/api/admin/tags', 'tag'),

    carouselCards: _.merge(generateCrudRoutes('/api/admin/carousel_cards', 'carousel_card'), {
      uploadCoverImage(id, bgImage, croppedAreaPixels) {
        const carouselCardFormData = new FormData()
        carouselCardFormData.append('carousel_card[cover_image]', bgImage, bgImage.fileName)
        appendCroppedFormData(carouselCardFormData, 'carousel_card', 'cover_image_cropped_area', croppedAreaPixels)


        return Axios.patch(`/api/admin/carousel_cards/${id}`, carouselCardFormData)
      },
      removeCoverImage(id) {
        return Axios.delete(`/api/admin/carousel_cards/${id}/cover_image`)
      },
    }),

    companyConfigurations,

    defaultUserSettings,

    timezones: generateCrudRoutes('/api/admin/timezones', 'timezone'),

    externalCalendars: generateCrudRoutes('/api/admin/external_calendars', 'externalCalendar'),

    externalEmailAuthentications: generateCrudRoutes('/api/admin/external_email_authentications'),

    calendars: {
      upcomingEvents(queryParams) {
        return Axios.get(searchURI('/api/admin/calendars/upcoming_events', queryParams))
      },

      fetchEvent(url) {
        return Axios.get(`/api/admin/calendars/event?edit_event_url=${url}`)
      },

      fetchCalendars() {
        return Axios.get('/api/admin/calendars')
      },
    },

    articleTypes: generateCrudRoutes('/api/admin/article_types', 'article_type'),

    stepTemplates: {
      fetchAll(params) {
        return Axios.get('/api/admin/journey/step_templates', snakeCaseKeysObjectsOnly({ params }))
      },

      fetchAvailableTemplates(params) {
        return Axios.get('/api/admin/journey/step_templates/available_templates', { params })
      },

      copy(params) {
        return Axios.post(`/api/admin/journey/step_templates/${params.id}/copy`, params)
      },

      destroy(id) {
        return Axios.delete(`/api/admin/journey/step_templates/${id}`)
      },

      batchUpdate(params) {
        return Axios.patch('/api/admin/journey/step_templates/batch_update', params)
      },
    },

    calendarEventTemplates: generateCrudRoutes('/api/admin/journey/calendar_event_templates', 'calendar_event_template'),

    communications: {
      ...generateCrudRoutes('/api/admin/journey/communications', 'communication'),
      sendPreviewEmail(id, userIds) {
        return Axios.post(`/api/admin/journey/communications/${id}/send_preview_email`, { userIds })
      },
      sendPreviewSms(id, userIds) {
        return Axios.post(`/api/admin/journey/communications/${id}/send_preview_sms`, { userIds })
      },
    },

    calendarEvents: generateCrudRoutes('/api/admin/journey/calendar_events', 'calendar_event'),

    taskTemplates: {
      ...generateCrudRoutes('/api/admin/journey/task_templates', 'task_template'),
      copy(id, name) {
        return Axios.post(`/api/admin/journey/task_templates/${id}/copy`, { name })
      },
    },

    introductionTemplates: generateCrudRoutes('/api/admin/journey/introduction_templates', 'introduction_template'),

    meetings: generateCrudRoutes('/api/admin/journey/meetings', 'meeting'),

    tasks: generateCrudRoutes('/api/admin/journey/tasks', 'task'),

    taskableSteps: {
      fetchAll(params) {
        return Axios.post('/api/admin/journey/taskable_steps', params)
      },

      exportXlsx(params) {
        return Axios.post('/api/admin/journey/taskable_steps/export_xlsx', params)
      },
    },

    introductions: generateCrudRoutes('/api/admin/journey/introductions', 'introduction'),

    onboardingBuddies: {
      fetchAll(params) {
        return Axios.get('/api/admin/journey/onboarding_buddies', snakeCaseKeysObjectsOnly({ params }))
      },

      create(params) {
        return Axios.post('/api/admin/journey/onboarding_buddies', params)
      },

      reassign(params) {
        return Axios.post('/api/admin/journey/onboarding_buddies/reassign', params)
      },

      unassign(params) {
        return Axios.delete('/api/admin/journey/onboarding_buddies/unassign', { data: params })
      },

      askToOptIn(params) {
        return Axios.post('/api/admin/journey/onboarding_buddies/ask_to_opt_in', params)
      },

      askAllToOptIn() {
        return Axios.post('/api/admin/journey/onboarding_buddies/ask_all_to_opt_in')
      },

      excludeUsers(userIds) {
        return Axios.patch('/api/admin/journey/onboarding_buddies/exclude_users', { userIds })
      },

      reincludeUsers(userIds) {
        return Axios.patch('/api/admin/journey/onboarding_buddies/reinclude_users', { userIds })
      },

      optIn(userId) {
        return Axios.post('/api/admin/journey/onboarding_buddies/opt_in', { userId })
      },
    },

    steps: {
      fetchAll(params) {
        const request = {
          params,
        }
        return Axios.get('/api/admin/journey/steps', snakeCaseKeysObjectsOnly(request))
      },

      triggerNow(id, resetData) {
        return Axios.patch(`/api/admin/journey/steps/${id}/trigger_now`, { resetData })
      },

      create(params) {
        return Axios.post('/api/admin/journey/steps', params)
      },
    },

    groups: {
      ...generateCrudRoutes('/api/admin/groups', 'group'),
      exportCsv(id) {
        return Axios.get(`/api/admin/groups/${id}/export_csv`)
      },
    },

    groupMemberships: {
      fetchAll(groupId, queryParams = null) {
        return Axios.get(searchURI(`/api/groups/${groupId}/members`, queryParams))
      },

      create(groupMembership, removeFollower = false) {
        return Axios.post(`/api/groups/${groupMembership.groupId}/members`, { groupMembership, removeFollower })
      },

      update(groupMembership) {
        return Axios.patch(`/api/groups/${groupMembership.groupId}/members/${groupMembership.id}`, { groupMembership })
      },

      destroy(groupMembership) {
        return Axios.delete(`/api/groups/${groupMembership.group.id}/members/${groupMembership.id}`)
      },

      batchCreate(group, userIds) {
        return Axios.post(`/api/groups/${group.id}/members/batch_create`, { userIds })
      },
    },

    groupTypes: generateCrudRoutes('/api/admin/group_types', 'group_type'),

    users: _.merge(generateCrudRoutes('/api/admin/users', 'user'), {
      fetchSoftLaunchUsers(opts) {
        return Axios.get(searchURI('/api/admin/users/soft_launch_users', opts))
      },

      fetchSoftLaunchUsersCount() {
        return Axios.get('/api/admin/users/soft_launch_users_count')
      },

      fetchUsersApprovedForExternalAlerts(opts) {
        return Axios.get(searchURI('/api/admin/users', { approvedForExternalAlerts: true, ...opts }))
      },

      grantSoftLaunchAccess(id) {
        return Axios.post(`/api/admin/users/${id}/grant_soft_launch_access`)
      },

      revokeSoftLaunchAccess(id) {
        return Axios.post(`/api/admin/users/${id}/revoke_soft_launch_access`)
      },

      fetchUserPermissions(id) {
        return Axios.get(`/api/admin/users/${id}/permissions`)
      },

      addPermission(id, permission) {
        return Axios.patch(`/api/admin/users/${id}/add_permission`, { permission })
      },

      removePermission(id, permission) {
        return Axios.patch(`/api/admin/users/${id}/remove_permission`, { permission })
      },

      getUsersCountByPermission() {
        return Axios.get('/api/admin/users/users_count_by_permission')
      },

      approveExternalAlerts(id) {
        return Axios.post(`/api/admin/users/${id}/approve_external_alerts`)
      },

      unapproveExternalAlerts(id) {
        return Axios.post(`/api/admin/users/${id}/unapprove_external_alerts`)
      },

      fetchSelectableOptionValues() {
        return Axios.get('/api/admin/users/selectable_option_values')
      },

      mergePreboardingUsers(params) {
        return Axios.patch('/api/admin/users/merge_preboarding_users', params)
      },

      bulkUpdate(params) {
        return Axios.patch('/api/admin/users/bulk_update', params)
      },

      deactivateAll(userIds) {
        return Axios.post('/api/admin/users/deactivate_all', { userIds })
      },
    }),

    newHires: {
      fetchAll(opts) {
        return Axios.get('/api/admin/journey/new_hires', snakeCaseKeysObjectsOnly({ params: opts }))
      },

      fetch(id, queryParams) {
        return Axios.get(searchURI(`/api/admin/journey/new_hires/${id}`, queryParams))
      },

      removeFromOnboardingBuddies(id, params) {
        return Axios.patch(`/api/admin/journey/new_hires/${id}/remove_from_onboarding_buddies`, params)
      },

      updateOnboardingBuddyAvailability(id, params) {
        return Axios.patch(`/api/admin/journey/new_hires/${id}/update_onboarding_buddy_availability`, params)
      },
    },

    preboarding: {
      previews: {
        start() {
          return Axios.post('/api/admin/journey/preboarding/previews/start')
        },

        stop() {
          return Axios.delete('/api/admin/journey/preboarding/previews/stop')
        },
      },
    },

    surveyForms: {
      ...generateCrudRoutes('/api/admin/survey/forms', 'survey_form'),

      copy(id, name) {
        return Axios.post(`/api/admin/survey/forms/${id}/copy`, { surveyForm: { name } })
      },

      exportResponses(id, params) {
        return Axios.post(`/api/admin/survey/forms/${id}/export_responses.csv`, params)
      },

      fetchUsersWhoHaveNotResponded(formId, queryParams = {}) {
        return Axios.get(searchURI(`/api/admin/survey/forms/${formId}/users_who_have_not_responded`, queryParams))
      },

      sendNotification(formId, params) {
        return Axios.post(`/api/admin/survey/forms/${formId}/send_notification`, params)
      },

      fetchAvailableTemplates() {
        return Axios.get('/api/admin/survey/forms/available_templates')
      },

      resendNotifications(formId, params) {
        return Axios.post(`/api/admin/survey/forms/${formId}/resend_notifications`, params)
      },
    },

    surveyResponses: {
      fetchAll(formId, queryParams = {}) {
        return Axios.get(searchURI(`/api/admin/survey/forms/${formId}/responses`, queryParams))
      },

      fetch(formId, responseId) {
        return Axios.get(`/api/admin/survey/forms/${formId}/responses/${responseId}`)
      },
    },

    surveyQuestions: {
      fetch(formId, id, queryParams = {}) {
        return Axios.get(searchURI(`/api/admin/survey/forms/${formId}/questions/${id}`, queryParams))
      },
    },

    surveyAnswers: {
      fetchAll(formId, questionId, queryParams = {}) {
        return Axios.get(searchURI(`/api/admin/survey/forms/${formId}/questions/${questionId}/answers`, queryParams))
      },
    },

    userSkillTaggings: {
      createTagging(params) {
        return Axios.post('/api/admin/user_skill_taggings', params)
      },

      destroyTagging(params) {
        return Axios.delete('/api/admin/user_skill_taggings', { data: params })
      },
    },

    integrationCredentials: {
      create(integrationCredential) {
        const params = {
          integrationCredential: {
            type: integrationCredential.type,
            credentials: integrationCredential.credentials,
          },
        }

        return Axios.post('/api/admin/integration_credentials', params)
      },
    },

    itAdmin: {
      fetch() {
        return Axios.get('/api/admin/it_admin')
      },

      fetchMergedevLinkToken() {
        return Axios.get('/api/admin/it_admin/mergedev_link_token')
      },

      setupMergedev(publicToken) {
        return Axios.post('/api/admin/it_admin/setup_mergedev', { publicToken })
      },

      update(company) {
        return Axios.patch('/api/admin/it_admin', { company })
      },

      confirmTeamsBotSdkInstalled(workspaceId, teamId) {
        return Axios.patch('/api/admin/it_admin/confirm_microsoft_teams_bot_installed', { id: workspaceId, teamId })
      },

      configureSSO(params) {
        return Axios.patch('/api/admin/it_admin/configure_sso', params)
      },

      installSAML(params) {
        return Axios.patch('/api/admin/it_admin/install_saml', params)
      },

      uninstall(integration, params) {
        return Axios.patch('/api/admin/it_admin/uninstall', { ...params, integration })
      },

      fetchConfluenceSpaces() {
        return Axios.get('/api/admin/it_admin/confluence_spaces')
      },

      updateConfluenceSpaces(confluenceSpaces) {
        return Axios.patch('/api/admin/it_admin/update_confluence_spaces', { confluenceSpaces })
      },

      updateSessionConfiguration(session) {
        return Axios.patch('/api/admin/it_admin/update_session_configuration', { session })
      },

      updateSearchRestrictedUserColumns(searchRestrictedUserColumns) {
        return Axios.patch('/api/admin/it_admin/update_search_restricted_user_columns', { searchRestrictedUserColumns })
      },

      installGoogle(googleCredentials) {
        return Axios.patch('/api/admin/it_admin/install_google', { googleCredentials })
      },

      installBloomfire(bloomfireCredentials) {
        return Axios.patch('/api/admin/it_admin/install_bloomfire', { bloomfireCredentials })
      },

      installBambooHR(bambooHRCredentials) {
        return Axios.patch('/api/admin/it_admin/install_bamboohr', { bambooHRCredentials })
      },

      installAshby(ashbyCredentials) {
        return Axios.patch('/api/admin/it_admin/install_ashby', { ashbyCredentials })
      },

      installAdp(adpCredentials) {
        return Axios.patch('/api/admin/it_admin/install_adp', { adpCredentials })
      },

      installJobvite(jobviteCredentials) {
        return Axios.patch('/api/admin/it_admin/install_jobvite', { jobviteCredentials })
      },

      installGreenhouse(greenhouseCredentials) {
        return Axios.patch('/api/admin/it_admin/install_greenhouse', { greenhouseCredentials })
      },

      installWorkday(workdayCredentials) {
        return Axios.patch('/api/admin/it_admin/install_workday', { workdayCredentials })
      },
    },

    company: {
      fetchAll(opts) {
        const options = { page: 1, ...opts }

        return Axios.get(searchURI('/api/admin/companies', options))
      },

      fetch(id) {
        return Axios.get(`/api/admin/companies/${id}`)
      },

      create(company) {
        return Axios.post('/api/admin/companies', { company })
      },

      generateDemoData(company, email) {
        return Axios.post(`/api/admin/companies/${company.id}/generate_demo_company_data`, { email })
      },

      update(company) {
        return Axios.patch(`/api/admin/companies/${company.id}`, { company })
      },

      destroy(company) {
        return Axios.delete(`/api/admin/companies/${company.id}`)
      },

      uploadFile(id, file, fileAttributeName) {
        const formData = new FormData()
        formData.append(`company[${fileAttributeName}]`, file, file.fileName)
        return Axios.patch(`/api/admin/companies/${id}`, formData)
      },

      impersonationHistory: {
        fetchAll(opts) {
          const options = { page: 1, ...opts }
          return Axios.get(searchURI('/api/admin/companies/impersonation_history', options))
        },
      },
    },

    customStyles: {
      update(company) {
        return Axios.patch(`/api/admin/styles/${company.id}`, { company })
      },
      uploadImage(id, image, imageName) {
        const formData = new FormData()
        formData.append(`company[${imageName}]`, image, image.fileName)
        return Axios.patch(`/api/admin/styles/${id}`, formData)
      },
    },

    restrictedAudienceConfigurations: _.merge(generateCrudRoutes('/api/admin/restricted_audience_configurations', 'restricted_audience_configuration'), {
      startPreview(data) {
        return Axios.post('/api/admin/restricted_audience_configurations/start_preview', { restricted_audience_configuration: data })
      },

      stopPreview() {
        return Axios.post('/api/admin/restricted_audience_configurations/stop_preview')
      },
    }),

    userImports: {
      fetchAll(queryParams) {
        return Axios.get(searchURI('/api/admin/user_imports', queryParams))
      },
      fetch(id) {
        return Axios.get(`/api/admin/user_imports/${id}`)
      },
      createOrFetchHRISUserImport(isOnlyModified = true) {
        return Axios.post('/api/admin/user_imports/create_hris_user_import', { onlyModified: isOnlyModified })
      },
      createOrFetchATSUserImport() {
        return Axios.post('/api/admin/user_imports/create_ats_user_import')
      },
      createOrFetchCsvUserImport(csv, { preboarding, dateFormat } = {}) {
        const formData = new FormData()
        formData.append('csv[file]', csv, csv.fileName)

        if (preboarding) {
          formData.append('preboarding', true)
        }

        formData.append('date_format', dateFormat)

        return Axios.post('/api/admin/user_imports/create_csv_user_import', formData)
      },
      relatedUsersFromFailedNamelyImportRecord(email, employeeId) {
        return Axios.get(
          searchURI('/api/admin/user_imports/related_users_from_failed_namely_import_record', { email, employeeId })
        )
      },
    },

    googleGroups: {
      fetchAll(queryParams) {
        return Axios.get(searchURI('/api/admin/google_groups', queryParams))
      },
    },

    metrics: {
      generateReport(reportType, params, format = 'csv') {
        return Axios.post(`/api/admin/metrics/${reportType}.${format}`, params)
      },
    },

    managedGroups: _.merge(generateCrudRoutes('/api/admin/managed_groups', 'managed_group'), {
      sync() {
        return Axios.post('/api/admin/managed_groups/sync')
      },
    }),

    adminAlerts: {
      fetchAll(params) {
        return Axios.get('/api/admin/admin_alerts', { params: snakeCaseKeysObjectsOnly(params) })
      },

      fetchUserAdminAlerts(userId) {
        return Axios.get(`/api/admin/users/${userId}/admin_alerts`)
      },

      createAdminAlert(userId, name) {
        return Axios.post(`/api/admin/users/${userId}/admin_alerts`, { adminAlert: { name } })
      },

      destroyAdminAlert(userId, alertId) {
        return Axios.delete(`/api/admin/users/${userId}/admin_alerts/${alertId}`)
      },

      getCountByName() {
        return Axios.get('/api/admin/admin_alerts/count_by_name')
      },
    },

    chat: {
      userGroups: {
        ...generateCrudRoutes('/api/admin/chat/user_groups', 'chat_user_group'),
      },

      channels: {
        sync() {
          return Axios.post('/api/admin/chat/channels/sync')
        },
      },

      chatUsers: {
        sync() {
          return Axios.post('/api/admin/chat/chat_users/sync')
        },
      },
    },

    appLauncher: {
      apps: generateCrudRoutes('/api/admin/app_launcher/apps', 'app'),
    },

    customReactions: {
      importFromSlack() {
        return Axios.post('/api/admin/custom_reactions/import_from_slack')
      },
    },

    htmlSummaries: {
      create(recordType, html, params = {}) {
        return Axios.post('/api/admin/html_summaries', { recordType, html, ...params })
      },

      createForNotifications(recordType, html, params = {}) {
        return Axios.post('/api/admin/html_summaries/create_for_notifications', { recordType, html, ...params })
      },
    },

    socialShares: {
      extractOpengraphAttributes(url) {
        return Axios.get('/api/admin/social_shares/extract_opengraph_attributes', { params: { url } })
      },
    },
  },
  analytics: {
    trackEvent(name, properties) {
      return Axios.post('/api/analytics/track_event', { name, properties })
    },
  },
}

export default API
