import generateCrudRoutes from 'services/generateCrudRoutes'

const buildIPAccessRulePayload = (ipAccessRule) => {
  const ipAccessRuleAttributes = _.pick(ipAccessRule, [
    'id',
    'address',
    'authorizationType',
  ])

  return ipAccessRuleAttributes
}

export default {
  ...generateCrudRoutes('/api/admin/ip_access_rules', 'ip_access_rule', {}, buildIPAccessRulePayload),
}
